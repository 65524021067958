import React from "react";
import {NewWishListPanel} from "../../component/wishlist/newWishListPanel";
import {InfoPanel} from "../../component/infopanel/infoPanel";
import {TopMenu} from "../../component/topmenu/topMenu";

const MainPage: React.FC = (_props) => {

    return <>
        <TopMenu/>
        <NewWishListPanel/>
        <InfoPanel/>
    </>
}

export {MainPage};