import React from "react";
import {useLocation} from "react-router-dom";
import {TopMenu} from "../../component/topmenu/topMenu";

const WishListPage: React.FC = (_props) => {
    const location = useLocation();
    const wishListId: string = location.pathname.substring('/wishlist/'.length);
    return <>
        <TopMenu/>
        Current Location: {wishListId}
    </>
}

export {WishListPage};