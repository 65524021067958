import {TopMenu} from "../../component/topmenu/topMenu";
import {Container, Grid, GridRow, Header, Icon, Image} from "semantic-ui-react";
import React from "react";
import {Footer} from "../../component/footer/footer";

import JeweleryImage from '../../images/jewelery.png';
import BooksImage from '../../images/books.png';
import KitchenImage from '../../images/kitchen.png';
import TravelImage from '../../images/travel.png';
import CouponImage from '../../images/coupon.png';

const IdeasPage: React.FC = () => {

    const circular = {width: 300, height: 300};
    return <>
        <TopMenu/>
        <Grid stackable>
            <GridRow>
                <Container textAlign={'center'} text>
                    <Icon circular inverted color='blue' size='huge' name='lightbulb'/>
                    <Header as='h1'>Ideenwelt
                    </Header>
                </Container>
            </GridRow>
            <GridRow>
                <Container>
                    <p>
                        Geschenkideen können für jeden Anlass und jede Person unterschiedlich sein. Einige der am häufigsten
                        gekauften Geschenke sind jedoch immer wieder die gleichen.
                    </p>
                </Container>
            </GridRow>
            <GridRow/>
            <GridRow>
                <Container>
                    <Image circular style={circular} src={JeweleryImage} size='large' floated='left'/>
                    <br/>
                    <Header as='h2'>Schmuck</Header>
                    <p>
                        Eine klassische Geschenkidee ist ein Schmuckstück. Ob es sich um eine Uhr, eine Halskette oder
                        Ohrringe
                        handelt, Schmuck kann ein besonderes und persönliches Geschenk sein, das jahrelang getragen werden
                        kann.
                    </p>
                </Container>
            </GridRow>
            <GridRow>
                <Container>
                    <Image style={circular} circular src={CouponImage} size='large' floated='right'/>
                    <br/>
                    <Header as='h2'>Gutscheine</Header>
                    <p>
                        Ein weiteres beliebtes Geschenk ist ein Gutschein. Ob für ein Restaurant, ein Einkaufszentrum oder
                        eine
                        spezielle Aktivität, Gutscheine ermöglichen es dem Empfänger, sich etwas zu wünschen, was sie sich
                        sonst
                        vielleicht nicht leisten könnten.
                    </p>
                </Container>
            </GridRow>
            <GridRow>
                <Container>
                    <Image style={circular} circular src={BooksImage} size='large' floated='left'/>
                    <br/>
                    <Header as='h2'>Bücher</Header>
                    <p>
                        Für diejenigen, die gerne lesen, kann ein Buch oder eine Kindle eine tolle Geschenkidee sein. Es
                        gibt
                        Bücher
                        für jeden Geschmack und jede Altersgruppe, und es gibt immer die Möglichkeit, dass das Buch, das man
                        verschenkt, jemanden inspiriert oder beeinflusst.
                    </p>
                </Container>
            </GridRow>
            <GridRow>
                <Container>
                    <Image style={circular} circular src={TravelImage} size='large' floated='right'/>
                    <br/>
                    <Header as='h2'>Reisen</Header>
                    <p>
                        Für diejenigen, die gerne reisen, kann eine Reise oder ein Hotelaufenthalt eine aufregende
                        Geschenkidee
                        sein. Es gibt so viele Orte auf der Welt zu entdecken, und eine Reise gibt jemandem die Möglichkeit,
                        neue
                        Kulturen zu erleben und unvergessliche Erinnerungen zu schaffen.
                    </p>
                </Container>
            </GridRow>
            <GridRow>
                <Container>
                    <Image style={circular} circular src={KitchenImage} size='large' floated='left'/>
                    <br/>
                    <Header as='h2'>Küchenutensilien</Header>
                    <p>
                        Für diejenigen, die gerne kochen oder backen, kann ein Kochbuch oder Küchenutensilien eine tolle
                        Geschenkidee sein. Es gibt unzählige Kochbücher mit Rezepten aus der ganzen Welt und es gibt immer
                        die
                        Möglichkeit, dass jemand durch das Geschenk zu einem besseren Koch wird.
                    </p>
                </Container>
            </GridRow>
            <GridRow>
                <Container>
                    <p>
                        Es gibt so viele Geschenkideen zur Verfügung und es kommt immer auf die Person an, für die man das
                        Geschenk
                        kauft. Ob es sich um ein materielles Geschenk handelt oder um eine Erfahrung, die man teilen kann,
                        es
                        ist
                        wichtig, dass das Geschenk von Herzen kommt und dass es die Person, die es erhält, glücklich macht.
                    </p>
                </Container>
            </GridRow>
            <GridRow/>
        </Grid>
        <br/>
        <Footer/>
    </>
}

export {IdeasPage};