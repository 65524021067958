interface CreateWishListResponse {
    editWishListId: string;
}


const WishListService = {

    async createWishList(name: string, multiPerson: boolean, reservable: boolean) {
        const requestOptions: RequestInit = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Time-Zone': 'Europe/Berlin'},
            credentials: 'same-origin',
            body: JSON.stringify({name, multiPerson, reservable})
        };

        return fetch('/api/wishlist/', requestOptions)
            .then(response => response.json())
            .then(json => json as CreateWishListResponse)
            .then(response => response.editWishListId);
    }
}

export default WishListService;