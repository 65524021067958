import React from "react";

import {Router} from "@remix-run/router/dist/router";
import {createBrowserRouter} from "react-router-dom";
import {MainPage} from "../../page/mainpage/mainPage";
import {ErrorPage} from "../../page/errorpage/errorPage";
import {WishListPage} from "../../page/wishlistpage/wishListPage";
import {IdeasPage} from "../../page/ideaspage/ideasPage";
import {ImprintPage} from "../../page/imprintpage/ImprintPage";
import {ContactPage} from "../../page/kontakt/ContactPage";

const router: Router = createBrowserRouter([
    {
        path: "/",
        element: <MainPage/>,
        errorElement: <ErrorPage/>
    },
    {
        path: '/ideen',
        element: <IdeasPage/>,
    },
    {
        path: '/impressum',
        element: <ImprintPage/>,
    },
    {
        path: '/kontakt',
        element: <ContactPage />
    },
    {
        path: "w/*",
        element: <WishListPage/>
    },
    {
        path: 'e/*',
        element: <div>Edit Wishlist</div>
    }
]);

export default router;