import React from "react";
import {Button, Container, Grid, GridColumn, GridRow, Header, Icon, Segment} from "semantic-ui-react";
import './infoPanel.css';
import {Footer} from "../footer/footer";
import {useMediaQuery} from "../../hook/useMediaQuery";
import {useNavigate} from "react-router-dom";

const square = {width: 275, height: 275}

const InfoPanel: React.FC = (_props) => {
    const isMobile = useMediaQuery('(max-width: 768px)')
    const navigate = useNavigate();
    const navigateTo = (event: React.MouseEvent, path: string) => {
        event.preventDefault();
        navigate(path);
    }
    return <>
        <Grid columns={'equal'} stackable>
            <GridRow className={'usecase-panel'} divided stretched>
                <GridColumn>
                    <Container textAlign={'center'}>
                        <Icon circular inverted color='red' size='huge' name='birthday cake'/>
                        <Header as='h3'> Geburtstage
                        </Header>
                        <p>Feiere Deinen Geburtstag mit Freude und ohne Stress! Mit unseren Geburtstagswunschlisten
                            kannst
                            Du Deine Freunde und Familie darum bitten, Dir genau das zu schenken, was Du Dir wünschst.
                            Vermeide ungewollte Geschenke und sorge dafür, dass Dein Geburtstag unvergesslich wird.
                            Erstelle
                            jetzt Deine Wunschliste und teile sie mit Deinen Lieben.</p>
                    </Container>
                </GridColumn>
                <GridColumn>
                    <Container textAlign={'center'}>
                        <Icon circular inverted color='green' size='huge' name='tree'/>
                        <Header as='h3'> Weihnachten
                        </Header>
                        <p>Erfülle dir und deinen Liebsten dieses Weihnachten die Wünsche mit unserer
                            Weihnachtswunschliste!
                            Stelle deine Wünsche ein und teile sie mit Freunden und Familie. So kannst du sicher sein,
                            dass
                            du genau das bekommst, was du dir wünschst. Mache jetzt deine Weihnachtswunschliste bei uns
                            und
                            erlebe ein besonderes Weihnachtsfest!</p>
                    </Container>
                </GridColumn>
                <GridColumn textAlign={'center'}>
                    <Container textAlign={'center'}>
                        <Icon circular inverted color='blue' size='huge' name='heart'/>
                        <Header as='h3'> Hochzeiten
                        </Header>
                        <p>Startet eure Ehe mit einer perfekten Geschenkliste! Mit unserern Hochzeitswunschlisten
                            könnt ihr eure Wünsche einfach aufnehmen und mit Freunden und Familie teilen. Ob es sich um
                            praktische Gegenstände für den Alltag handelt oder um eine Traumreise, unsere Wunschlisten
                            machen es
                            einfach, eure Wünsche zu verwirklichen. Erstellt jetzt eure Wunschliste und sorgt dafür,
                            dass
                            eure Hochzeit unvergesslich wird!</p>
                    </Container>
                </GridColumn>
                <GridColumn>
                    <Container textAlign={'center'}>
                        <Icon circular inverted color='yellow' size='huge' name='user circle'/>
                        <Header as='h3'> Baby Partys
                        </Header>
                        <p>Willkommen im Club der Eltern! Mit unserern Wunschlisten könnt ihr eure
                            Wünsche für euer neues Familienmitglied einfach aufnehmen und teilen. Ob es sich um
                            Dinge wie Windeln und Babykleidung handelt oder um besondere Geschenke wie Spielzeug oder
                            einen
                            Kinderwagen, wir haben alles was ihr braucht. Erstellt jetzt eure Wunschliste und teilt sie
                            mit
                            Freunden und Familie, damit eure kleine Familie alles hat was sie braucht.</p>
                    </Container>
                </GridColumn>
            </GridRow>
            <GridRow/>
            <GridRow>
                <Container textAlign='center'>
                    <Header as='h2'>
                        Deine persönliche Wunschliste in nur 3 Schritten:
                    </Header>
                </Container>
            </GridRow>
            <GridRow style={{background: '#55a2ba'}}>
                <GridColumn>
                    <Grid columns='2' stackable padded relaxed>
                        <GridRow centered columns='4'>
                            <GridColumn>
                                <Segment size='massive' circular={!isMobile} style={isMobile ? {} : square} secondary
                                         color='red'>
                                    <Header as='h2'>
                                        1.
                                    </Header>
                                    <p>
                                        Gib deiner Wunschliste einen Namen und entscheide wie sie erstellt werden soll.
                                    </p>
                                </Segment>
                            </GridColumn>
                            <GridColumn>
                                <Segment size='massive' circular={!isMobile} style={isMobile ? {} : square} secondary
                                         color='yellow'>
                                    <Header as='h2'>
                                        2.
                                    </Header>
                                    <p>
                                        Erstelle deine Wunschliste und fange an deine Wünsche zu erfassen.
                                    </p>
                                </Segment>
                            </GridColumn>
                            <GridColumn>
                                <Segment size='massive' circular={!isMobile} style={isMobile ? {} : square} secondary
                                         color='green'>
                                    <Header as='h2'>
                                        3.
                                    </Header>
                                    <p>
                                        Teile deine Wunschliste mit allen deinen Freunden und deiner Familie.
                                    </p>
                                </Segment>
                            </GridColumn>
                        </GridRow>
                    </Grid>
                </GridColumn>
            </GridRow>
            <GridRow className={'usecase-panel'} >
                <GridColumn>
                    <Container textAlign={'center'}>
                        <Icon circular inverted color='yellow' size='huge' name='shopping cart'/>
                        <Header as='h2'> Entdecke die grenzenlosen Möglichkeiten mit <i>WishListr</i>!</Header>
                        <p>
                            Mit unserem einfachen und intuitiven System kannst du ganz einfach deine Wunschlisten
                            erstellen
                            und verwalten. Egal, ob du Geburtstagsgeschenke, Weihnachtswünsche oder sogar
                            Hochzeitslisten
                            zusammenstellen möchtest - Wunschlisten machen es möglich.
                            <br/><br/>
                            Teile deine Liste mit Freunden und Familie und gib ihnen die Möglichkeit, dir genau das zu
                            schenken, was du dir wünschst. Vergiss das Raten und das unnötige Beschenken - mit
                            Wunschlisten
                            trifft jedes Geschenk ins Schwarze.
                            <br/><br/>
                            Unsere Wunschlisten sind flexibel und anpassbar, du kannst jederzeit Wünsche hinzufügen,
                            ändern
                            oder entfernen. Auch die Möglichkeit, Wünsche in verschiedene Kategorien einzuteilen,
                            ermöglicht
                            es dir, deine Wünsche organisiert und übersichtlich zu halten. Besonders praktisch: Du
                            kannst
                            Wünsche sogar reservieren, damit sie nicht von anderen Personen gekauft werden, bevor du
                            bereit
                            bist sie zu erhalten.
                            <br/><br/>
                            Probier es jetzt aus und erlebe die Freiheit des Wunsches! Mit Wunschlisten hast du die
                            Kontrolle darüber, was du bekommst und du kannst sicher sein, dass jedes Geschenk genau das
                            ist,
                            was du dir wünschst. Entdecke die grenzenlosen Möglichkeiten mit Wunschlisten!</p>
                    </Container>
                </GridColumn>
            </GridRow>
        </Grid>
        <Footer/>
    </>
};

export {InfoPanel}