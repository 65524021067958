import React from "react";

import {Grid, GridColumn, GridRow, Header, List} from "semantic-ui-react";
import {useNavigate} from "react-router-dom";
import {NavigateFunction} from "react-router/dist/lib/hooks";


const Footer: React.FC = (_props) => {

    const navigate: NavigateFunction = useNavigate();

    const navigateTo = (event: React.MouseEvent, path: string) => {
        event.preventDefault();
        navigate(path);
    }

    return <div style={{marginTop: '1em'}}><Grid columns={'equal'} divided inverted padded sty>
        <GridRow color='black'>
            <GridColumn>
                <Header inverted as={'h4'}>Über uns</Header>
                <List>
                    <List.Item>
                        <List.Icon color='grey' name={'book'}/>
                        <List.Content>
                            <a href='/sitemap' onClick={(e) => navigateTo(e, '/sitemap')}>Sitemap</a>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon color='grey' name={'mail'}/>
                        <List.Content>
                            <a href='/kontakt' onClick={(e) => navigateTo(e, '/kontakt')}>Kontakt</a>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon color='grey' name={'warehouse'}/>
                        <List.Content>
                            <a href='/impressum' onClick={(e) => navigateTo(e, '/impressum')}>Impressum</a>
                        </List.Content>
                    </List.Item>
                </List>
            </GridColumn>
            <GridColumn>
                <Header inverted as={'h4'}>Dienstleistungen</Header>
                <List>
                    <List.Item>
                        <List.Icon color='grey' name={'gift'}/>
                        <List.Content>
                            <a href='/' onClick={(e) => navigateTo(e, '/')}>Wunschlisten erzeugen</a>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon color='grey' name={'lightbulb'}/>
                        <List.Content>
                            <a href='/ideen' onClick={(e) => navigateTo(e, '/ideen')}>Geschenkideen</a>
                        </List.Content>
                    </List.Item>
                </List>
            </GridColumn>
        </GridRow>
    </Grid>
    </div>
}

export {Footer};