import React, {useState} from "react";
import {Form, Grid, GridColumn, Header, Image, Segment} from "semantic-ui-react";
import './newWishListPanel.css';
import Logo from '../../images/logo.png'
import wishListService from "../../service/wishlistservice";
import {NavigateFunction} from "react-router/dist/lib/hooks";
import {useNavigate} from "react-router-dom";

const NewWishListPanel: React.FC = (_props) => {
    const [locked, setLocked] = useState(false);
    const [name, setName] = useState('');
    const [multiPerson, setMultiPerson] = useState<boolean>(false);
    const [reservable, setReservable] = useState<boolean>(true);

    const navigate: NavigateFunction = useNavigate();

    const redirect = (editWishListId: string) => {
        navigate(`/e/${editWishListId}`)
    };

    const createNewWishList: () => void = () => {
        if (!name || name.trim().length === 0) {
            return;
        }
        setLocked(() => true);
        wishListService.createWishList(name, multiPerson, reservable)
            .catch(redirect)
            .finally(() => setLocked(() => false));
    }

    return <Grid centered columns={4} className={'create-a-wish-panel'}>
        <GridColumn mobile={15} tablet={7} computer={5}>
            <Image centered src={Logo}/>
            <Segment raised className={'new-list'}>
                <Header as='h3'>
                    Kostenlos eine Wunschliste erstellen!
                </Header>
                <Form>
                    <Form.Field disabled={locked}>
                        <Form.Input required
                                    label={'Name der Wunschliste'}
                                    value={name} onChange={(e, v) => setName(v.value)}/>
                    </Form.Field>
                    <Form.Field disabled={locked}>
                        <Form.Checkbox toggle
                                       label={'Wünsche mehrerer Personen?'}
                                       checked={multiPerson}
                                       onChange={() => setMultiPerson((p) => !p)}/>
                    </Form.Field>
                    <Form.Field disabled={locked}>
                        <Form.Checkbox toggle
                                       label={'Wünsche können reserviert werden?'}
                                       checked={reservable}
                                       onChange={() => setReservable((p) => !p)}/>
                    </Form.Field>
                    <Form.Button fluid
                                 primary
                                 disabled={locked}
                                 content={'Erstellen'}
                                 onClick={createNewWishList}
                    />
                </Form>
            </Segment>
        </GridColumn>
    </Grid>
}

export {NewWishListPanel};