import {isRouteErrorResponse, useNavigate, useRouteError} from "react-router-dom";
import {Button, Container, Header, Icon, Segment} from "semantic-ui-react";
import {NavigateFunction} from "react-router/dist/lib/hooks";
import {TopMenu} from "../../component/topmenu/topMenu";
import React from "react";

const ErrorPage: React.FC = () => {
    const error: unknown = useRouteError();
    const navigate: NavigateFunction = useNavigate();
    const navigateToHome: (event: React.MouseEvent) => void = (event: React.MouseEvent) => {
        event.preventDefault();
        navigate('/');
    }

    if (isRouteErrorResponse(error)) {
        if (error.status == 404) {
            return <>
                <TopMenu/>
                <Container fluid>
                    <Segment color='red' textAlign='center' size='large'>
                        <Header as='h1'>
                            <Icon name='warning circle' size='huge' color='red'/>
                            Dies ist nicht die Wunschliste die du suchst!
                        </Header>
                        <p>Die eingegebene Wunschliste existiert nicht oder wurde entfernt.</p>
                        <p>Bitte kontrolliere die eingebene URL oder wende dich an die Ersteller:innen der
                            Wunschliste.</p>
                        <p><Button primary onClick={navigateToHome}>Zur Startseite zurück</Button></p>
                    </Segment>
                </Container>
            </>
        }
    }
    return <>
        <TopMenu/>
        <Container>
            <Segment color='red' textAlign='center' size='large'>
                <Header as='h1'>
                    <Icon name='warning circle' size='huge' color='red'/>
                    Oops, hier ist etwas schief gelaufen!
                </Header>
                <p>Bei der verarbeitung ist ein unerwarteter Fehler aufgetreten.</p>
                <p>Sollte dies wiederholt auftreten melde dich bitte bei uns.</p>
            </Segment>
        </Container>
    </>

};

export {ErrorPage};