import React, {useState} from "react";
import {Container, Icon, Menu, Sidebar} from "semantic-ui-react";
import {useLocation, useNavigate} from "react-router-dom";
import {useMediaQuery} from "../../hook/useMediaQuery";

const TopMenu: React.FC = (_props) => {
    const isMobile = useMediaQuery('(max-width: 768px)')
    const navigate = useNavigate();
    const location = useLocation();

    const [visible, setVisible] = useState(false)

    const menuItems = <>
        <Menu.Item>
            WishListr.de
        </Menu.Item>
        <Menu.Item
            name='Wunschliste erstellen'
            active={location.pathname === '' || location.pathname === '/'}
            onClick={() => navigate('/')}
            icon='gift'
        />
        <Menu.Item
            name='Ideenwelt'
            active={location.pathname === '/ideen'}
            onClick={() => navigate('/ideen')}
            icon='lightbulb'
        />
        <Menu.Item
            name='Kontakt'
            active={location.pathname === '/kontakt'}
            onClick={() => navigate('/kontakt')}
            icon='address book'
        />
    </>

    const regularMenu = <Menu size={'massive'} pointing>
        {menuItems}
    </Menu>;

    const mobileMenu = <Menu size={'massive'} pointing>
        <Menu.Item onClick={() => setVisible(!visible)}>
            <Icon name='bars'/>
        </Menu.Item>
        <Sidebar
            as={Menu}
            animation='overlay'
            icon='labeled'
            onHide={() => setVisible(false)}
            vertical
            visible={visible}
        >
            {menuItems}
        </Sidebar>
    </Menu>
    return isMobile ? mobileMenu : regularMenu;
}

export {TopMenu};