import React from "react";
import {TopMenu} from "../../component/topmenu/topMenu";
import {Footer} from "../../component/footer/footer";

const ImprintPage: React.FC = () => {

    return <>
        <TopMenu/>
        <Footer/>
    </>
};

export {ImprintPage};